// filename: gps.sign-field.model.ts
import {getTextAlignment, IField} from '@core/api/data-access';
import {DcIconStruct, FaIconSize} from '@shared-types';
import {SignFieldModel} from '../sign-field.model';
import {computed, Signal} from '@angular/core';
import {ISignalFormControlValidation} from '@shared/signals-extension';
import {TextBoxSignFieldModel} from './text-box.sign-field.model';

export class GpsSignFieldModel extends SignFieldModel {
	private _notAvailableValue: string;
	readonly fieldTypeIcon: DcIconStruct = {name: 'map-marker-alt', type: 'duotone', size: FaIconSize.xl};

	initValidators(): ISignalFormControlValidation[] {
		debugger;
		return [];
	}

	initSignalFC(): void {
		if (this.required)
			this.pageRef()?.signDocument.signCycle.signCycleIndicators.update(indicators => ({
				...indicators,
				hasRequiredGpsFields: true,
			}));
		this.signalFC.disable();
		if (!this._notAvailableValue) this._notAvailableValue = window.translate('pages.sign-doc.gps.not-available');
		this.signalFC.setValue(this._notAvailableValue);
	}

	isValid: Signal<boolean> = computed(() => {
		const isFilled = this.signalFC.value() !== this._notAvailableValue;
		const isRequired = this.required;
		return isRequired ? isFilled : true;
	});

	constructor(init: IField) {
		super(init);
		this._initCustomTypeStyles();
		this._notAvailableValue = window.translate('pages.sign-doc.gps.not-available');
	}

	_initCustomTypeStyles(): void {
		this.customTypeStyles = TextBoxSignFieldModel.textualFieldCustomStyles(this);
		this.customTypeStyles.pointerEvents = 'none';
		this.customTypeStyles.userSelect = 'none';
		this.customTypeStyles.cursor = 'not-allowed !important';
		this.customTypeStyles.background = 'transparent';
	}

	getValue(): string | null {
		return this.signalFC.getValue();
	}
}
