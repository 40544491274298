import {SignService} from '../sign.service';
import {computed, Signal} from '@angular/core';
import {SignFieldModel} from '../../models';
import {FieldType, IMetaTagValue} from '@core/api/data-access';

export class SignTagsService {
	protected readonly metaDataFields: Signal<SignFieldModel[]> = computed(() => {
		return this.signService
			.signCycleModel()
			.fields()
			.filter((field: SignFieldModel) => field.type === FieldType.metadata && !field?.isHidden && Boolean(field?.value));
	});

	protected readonly metaTags = computed(() => {
		return this.signService.signCycleModel().metaTags?.filter((tag: IMetaTagValue) => tag.visible);
	});

	public readonly tags$: Signal<(SignFieldModel | IMetaTagValue)[]> = computed(() => {
		return [...this.metaDataFields(), ...this.metaTags()];
	});

	constructor(protected signService: SignService) {}
}
