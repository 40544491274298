import {SignService} from '../sign.service';
import {ISignField} from '@global-data';
import {computed, Signal, WritableSignal} from '@angular/core';
import {environment} from '@shared-types';
import {CheckBoxSignFieldModel, SignFieldModel} from '../../models';
import {FieldType} from '@core/api/data-access';

export class SignFieldsInspectorService {
	/* ------------------------------------------------  Signals ------------------------------------------------ */
	private readonly activeField: WritableSignal<ISignField> = this.signService.Fields.activeField;

	private readonly activeFieldIndex: Signal<number> = computed(() => this.activeField()?.index);

	private readonly fillableFields: Signal<ISignField[]> = computed(() => {
		const fields = this.signService.Fields.fields().filter(field => (!SignFieldModel.isExternalField(field.type) && !field.signalFC.value()) || (field.type === FieldType.checkBox && !(field as CheckBoxSignFieldModel).checked()));
		return fields.sort(this.sortSignFields);
	});

	private readonly sortedFields: Signal<ISignField[]> = computed(() => {
		const fields = this.signService.Fields.internalFields();
		return fields.sort(this.sortSignFields);
	});

	private readonly topField: Signal<ISignField> = computed(() => {
		const fillableFields = this.fillableFields();
		return fillableFields[0];
	});

	private readonly previousActiveField: Signal<ISignField> = computed(() => {
		const activeFieldIndex = this.activeFieldIndex();
		if (!activeFieldIndex) return this.topField();
		const fillableFields = this.fillableFields();
		return fillableFields.reverse().find((field: ISignField) => field.index < activeFieldIndex);
	});

	private readonly nextActiveField: Signal<ISignField> = computed(() => {
		const activeFieldIndex = this.activeFieldIndex();
		if (!activeFieldIndex) return this.topField();
		const sortedFields = this.sortedFields();
		// return the next un-filled field
		return sortedFields.find((field: ISignField) => field.index > activeFieldIndex && (!field.signalFC.value() || (field.type === FieldType.checkBox && !(field as CheckBoxSignFieldModel).checked())));
	});
	/* -------------------------------------------------- Data -------------------------------------------------- */

	/* ------------------------------------------------  Constructor ------------------------------------------------ */
	constructor(private signService: SignService) {}

	/* ----------------------------------------------- Lifecycle Hooks ----------------------------------------------- */

	/* --------------------------------------------  PRIVATE METHODS -------------------------------------------- */
	/**
	 * @description Sorts the fields by their position on the document.
	 * - if the direction is rtl, the fields will be sorted from top-right to bottom-left.
	 * - if the direction is ltr, the fields will be sorted from top-left to bottom-right.
	 * @param a {ISignField} - The first field to compare.
	 * @param b {ISignField} - The second field to compare.
	 * @returns {number} - The result of the comparison.
	 */
	private sortSignFields(a: ISignField, b: ISignField): number {
		const direction = environment.direction === 'rtl' ? 'right' : 'left';
		// 1. sort by page number
		if (a.page !== b.page) return a.page - b.page;
		// 2. sort by top position
		const aRect = a.styles.rect();
		const bRect = b.styles.rect();
		if (aRect.top !== bRect.top) return aRect.top - bRect.top;
		// 3. sort by {direction} position
		return aRect[direction] - bRect[direction];
	}

	/* --------------------------------------------- PUBLIC METHODS --------------------------------------------- */
	public nextField(): void {
		const nextField = this.nextActiveField();
		if (!nextField) {
			const previousField = this.previousActiveField();
			if (!previousField) return;
			this.activeField.set(previousField);
			return;
		}
		this.activeField.set(nextField);
	}

	public previousField(): void {
		const previousField = this.previousActiveField();
		if (!previousField) return;
		this.activeField.set(previousField);
	}
}
