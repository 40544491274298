import {SignService} from '../sign.service';
import {computed, Signal} from '@angular/core';
import {AttachmentSignFieldModel, SignFieldModel} from '../../models';
import {FieldType, IAddAttachmentFile} from '@core/api/data-access';

export class SignFieldsAttachmentsService {
	/* -------------------------------------------------- Data -------------------------------------------------- */
	public readonly attachments: Signal<AttachmentSignFieldModel[]> = computed(() => {
		const fields: SignFieldModel[] = this.signService.Fields.fields();
		return fields.filter((field: SignFieldModel) => field.type === FieldType.attachment) as AttachmentSignFieldModel[];
	});

	/* ------------------------------------------------  Signals ------------------------------------------------ */

	public readonly allRequiredAttachments: Signal<AttachmentSignFieldModel[]> = computed(() => {
		return this.attachments().filter((field: AttachmentSignFieldModel) => field.isRequired());
	});

	public readonly allRequiredAttachmentsValid: Signal<boolean> = computed(() => {
		return this.allRequiredAttachments().every((field: AttachmentSignFieldModel) => field.isValid());
	});

	public readonly attachmentsToSubmit: Signal<IAddAttachmentFile[]> = computed(() => {
		const attachments: AttachmentSignFieldModel[] = this.attachments();
		return attachments.flatMap(attachment => attachment.asAttachmentRequestFile());
	});

	constructor(protected signService: SignService) {}
}
