import {PageRenderedEvent} from 'ngx-extended-pdf-viewer';
import {SignCycleModel} from '../cycle';
import {SignDocumentPageModel} from './page/sign-document-page.model';
import {SignFieldModel} from '../fields';
import {ISignDocument, StoredFile} from '@global-data';
import {getDocument} from 'pdfjs-dist';
import {from, map, Observable} from 'rxjs';
import {DocumentPdfUtilService} from '@utils/pdf';

export class SignDocumentModel extends StoredFile implements ISignDocument {
	pagesCount: number;
	pages: Map<number, SignDocumentPageModel> = new Map<number, SignDocumentPageModel>();

	constructor(
		public StoredFile: StoredFile,
		public signCycle: SignCycleModel,
	) {
		super(StoredFile.data, StoredFile.name);
	}

	initPages(totalPages: number, fields: SignFieldModel[]): void {
		for (let i = 1; i <= totalPages; i++) {
			if (!this.pages.has(i)) {
				const pageModel: SignDocumentPageModel = new SignDocumentPageModel(this, undefined, i);
				fields
					?.filter(f => f.page === i)
					.forEach((field: SignFieldModel) => {
						pageModel.addField(field);
					});
				this.pages.set(pageModel.pageNumber, pageModel);
			}
		}
	}

	setPage(pageRenderedEvent: PageRenderedEvent): SignDocumentPageModel {
		let page: SignDocumentPageModel = this.pages.get(pageRenderedEvent.pageNumber);
		if (!page) page = new SignDocumentPageModel(this, pageRenderedEvent);
		else page.pageRenderedEvent = pageRenderedEvent;

		this.pages.set(page.pageNumber, page);
		return page;
	}

	public loadPagesCount(): Observable<number> {
		DocumentPdfUtilService.setupWorker();
		return from(getDocument(this.StoredFile.webEncode).promise).pipe(
			map(pdf => {
				this.pagesCount = pdf.numPages;
				return pdf.numPages;
			}),
		);
	}
}
