// filename: date-field.sign-field.model.ts
import {getTextAlignment, IField} from '@core/api/data-access';
import {DcIconStruct, FaIconSize} from '@shared-types';
import {SignFieldModel} from '../sign-field.model';
import {computed, Signal} from '@angular/core';
import dayjs from 'dayjs';
import {DateTime, getDateTimeService} from '@utils/date-time';
import {DateFormatType, DefaultDateFormat} from '@global-data';
import {ISignalFormControlValidation} from '@shared/signals-extension';
import adaptDateFormatToDayjs = DateTime.DateTimeUnitTransformer.adaptDateFormatToDayjs;
import {TextBoxSignFieldModel} from './text-box.sign-field.model';

export class DateFieldSignFieldModel extends SignFieldModel {
	readonly fieldTypeIcon: DcIconStruct = {name: 'calendar-alt', type: 'duotone', size: FaIconSize.xl};

	initValidators(): ISignalFormControlValidation[] {
		debugger;
		return [];
	}

	initSignalFC(): void {
		this.signalFC.disable();
		// format date based on the format string:
		const formatString = this.settings$().DateFormat;
		const formattedDate = this._formatDate(formatString);
		this.signalFC.setValue(formattedDate);
		this.value = formattedDate;
	}

	isValid: Signal<boolean> = computed(() => {
		const isFilled = this.signalFC.value();
		return this.required ? !!isFilled : true;
	});

	_initCustomTypeStyles(): void {
		this.customTypeStyles = TextBoxSignFieldModel.textualFieldCustomStyles(this);
		this.customTypeStyles.pointerEvents = 'none';
		this.customTypeStyles.userSelect = 'none';
		this.customTypeStyles.cursor = 'not-allowed !important';
		this.customTypeStyles.background = 'transparent';
	}

	private _formatDate(formatString: string): string {
		if (!formatString) {
			formatString = getDateTimeService().defaultDateFormat() || DefaultDateFormat.DDMMYYYY;
		}
		const formats = [DefaultDateFormat.DDMMYYYY, DefaultDateFormat.MMDDYYYY, ...Object.flattenValues(DateFormatType)];
		let f: string = formats.find(format => format.toLowerCase() === formatString.toLowerCase());
		const date = dayjs();
		f = adaptDateFormatToDayjs(f);
		return getDateTimeService().formatDate(date, '', f?.toUpperCase() as TimeFormat);
	}

	constructor(init: IField) {
		super(init);
		this._initCustomTypeStyles();
	}

	getValue(): string | null {
		return this.signalFC.getValue();
	}
}
