import {ISignStation} from '@global-data';
import {StationModel} from '@cycles/data-access';
import {IStation} from '@core/api/data-access';

export class SignStationModel extends StationModel implements ISignStation {
	constructor(init: IStation) {
		super(init);
	}

	canSign(userId: string): boolean {
		return this.users.some(user => user.user === userId);
	}
}
