import {FeatureStoreNames} from '@shared-types';
import {Action, createFeature, createReducer, on} from '@ngrx/store';
import {cycleSignActions} from './cycle-sign.actions';
import {CycleSignStore} from './cycle-sign.models';
import cycleSignInitialState = CycleSignStore.cycleSignInitialState;
import ICycleSignState = CycleSignStore.ICycleSignState;

export const cycleSignFeature = createFeature({
	name: FeatureStoreNames.CYCLE_SIGN_FEATURE_KEY,
	reducer: createReducer(
		{...cycleSignInitialState},
		on(cycleSignActions.setSignCycle, (state, {cycle}) => ({...state, cycle})),
		on(cycleSignActions.loadSignCycle, state => ({...state, loading: true})),
		on(cycleSignActions.unloadSignCycle, state => ({...state, ...cycleSignInitialState})),
	),
});

export function cycleSignReducer(state: ICycleSignState | undefined, action: Action) {
	return cycleSignFeature.reducer(state, action);
}
