// filename: text-box.sign-field.model.ts
import {getTextAlignment, IField, ITextFieldValidationRuleSchema} from '@core/api/data-access';
import {DcIconStruct, FaIconSize} from '@shared-types';
import {SignFieldModel} from '../sign-field.model';
import {computed, signal, Signal, WritableSignal} from '@angular/core';
import {textFieldValidationRuleFactory} from '../../../logic';
import {ISignalFormControlValidation} from '@shared/signals-extension';

export class TextBoxSignFieldModel extends SignFieldModel {
	readonly fieldTypeIcon: DcIconStruct = {name: 'input-text', type: 'duotone', size: FaIconSize.xl};

	initSignalFC(): void {
		// TODO: init validation rules if exists
		// if (isDevMode()) debugger;
	}

	override validationRuleData: WritableSignal<ITextFieldValidationRuleSchema> = signal<ITextFieldValidationRuleSchema>(null);

	initValidators(): ISignalFormControlValidation[] {
		const validationRule = this.validationRuleData()?.validationRule;
		if (validationRule) {
			return [
				{
					name: validationRule.name,
					description: validationRule.description,
					errorMessage: validationRule.errorMessage,
					successMessage: validationRule.successMessage,
					validator: validationRule.validator,
				},
			];
		}
		return [];
	}

	isValid: Signal<boolean> = computed(() => {
		const isValid = this.signalFC.isValid();
		const isFilled = this.signalFC.value();
		const isRequired = this.required;
		return isRequired ? isValid && isFilled : isValid;
	});

	constructor(init: IField) {
		super(init);
		this._initCustomTypeStyles();
		this.validationRuleData.set(textFieldValidationRuleFactory(this));
	}

	_initCustomTypeStyles(): void {
		this.customTypeStyles = TextBoxSignFieldModel.textualFieldCustomStyles(this);
	}

	getValue(): string | null {
		return this.signalFC.getValue();
	}

	public static textualFieldCustomStyles(signField: SignFieldModel): CSSStyleDeclaration {
		const styles: CSSStyleDeclaration = {} as CSSStyleDeclaration;
		styles.textAlign = getTextAlignment(signField.settings$().Alignment);
		styles.pointerEvents = 'auto';
		styles.userSelect = 'auto';
		styles.cursor = 'auto';
		return styles;
	}
}
