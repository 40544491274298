import {IBaseStore, ISignCycle} from '@global-data';

export namespace CycleSignStore {
	/* ------------ CycleSign State Structure ------------ */
	export type ICycleSignState = IBaseStore & {
		cycle: ISignCycle;
	};

	/* ------------ CycleSign Initial State ------------ */
	export const cycleSignInitialState: ICycleSignState = {
		cycle: null,
		loading: false,
		loaded: false,
	};
}
