import {DcColorType} from '@shared-types';

export const SignStateType = {
	FieldsLeft: 'FieldsLeft',
	FieldsError: 'FieldsError',
	CanGoToAttachments: 'CanGoToAttachments',
	CanSubmit: 'CanSubmit',
	Submit: 'Submit',
} as const;
export type SignStateType = (typeof SignStateType)[keyof typeof SignStateType];
/* -------------------------------------------------------------------------------------------------------------- */

export type SignStateStruct = {
	type: SignStateType;
	color: DcColorType;
	disabled: boolean;
	value?: any;
};
