import {DcColorType} from '@shared-types';

export const SignButtonStateType = {
	CanSignInClick: 'CanSignInClick',
	CanGoToAddAttachments: 'CanGoToAddAttachments',
	CanSubmit: 'CanSubmit',
} as const;
export type SignButtonStateType = (typeof SignButtonStateType)[keyof typeof SignButtonStateType];
/* -------------------------------------------------------------------------------------------------------------- */

export type SignSubmissionButtonStateStruct = {
	type: SignButtonStateType;
	disabled: boolean;
	icon: string;
	label: string;
	color: DcColorType;
};
