import {FieldType, IField} from '@core/api/data-access';
import {FieldModel} from '@cycles/data-access';
import {AttachmentSignFieldModel, CheckBoxSignFieldModel, DateFieldSignFieldModel, GpsSignFieldModel, ImageSignFieldModel, MetaDataSignFieldModel, SignatureSignFieldModel, TextBoxSignFieldModel} from './field-types';
import {SignFieldModel} from './sign-field.model';
import {ISignService, isUserPropsPlaceholder, replaceUserPropsPlaceholder} from '@global-data';
import {MainInjector} from '@shared-types';
import {AuthService} from '@auth/data-access';

// Define a factory function with a generic parameter T that extends FieldType
export function SignFieldModelFactory(field: IField, signService: ISignService, pagesCount: number): SignFieldModel {
	const fieldModel: FieldModel = new FieldModel(field);
	fieldModel.page = fieldModel?.settings$().CalcPageFromEnd ? pagesCount - fieldModel.page + 1 : fieldModel.page;
	// Check the field type
	switch (fieldModel.type) {
		case FieldType.textBox:
			const textBoxSignFieldModel = new TextBoxSignFieldModel(fieldModel);
			textBoxSignFieldModel.setSignService(signService);

			// if the text box field contains placeholder of user data - replace it with the actual user data:
			if (isUserPropsPlaceholder(textBoxSignFieldModel.getValue())) {
				textBoxSignFieldModel.signalFC._fc.setValue(replaceUserPropsPlaceholder(textBoxSignFieldModel.value, MainInjector.get(AuthService).ActiveUser.activeUser()));
			}
			return textBoxSignFieldModel;
		case FieldType.dateField:
			const dateFieldSignFieldModel = new DateFieldSignFieldModel(fieldModel);
			dateFieldSignFieldModel.setSignService(signService);
			return dateFieldSignFieldModel;
		case FieldType.GPS:
			const gpsSignFieldModel = new GpsSignFieldModel(fieldModel);
			gpsSignFieldModel.setSignService(signService);
			return gpsSignFieldModel;
		case FieldType.metadata:
			const metaDataSignFieldModel = new MetaDataSignFieldModel(fieldModel);
			metaDataSignFieldModel.setSignService(signService);
			return metaDataSignFieldModel;
		case FieldType.attachment:
			const attachmentSignFieldModel = new AttachmentSignFieldModel(fieldModel);
			attachmentSignFieldModel.setSignService(signService);
			return attachmentSignFieldModel;
		case FieldType.signature:
			const signatureSignFieldModel = new SignatureSignFieldModel(fieldModel);
			signatureSignFieldModel.setSignService(signService);
			return signatureSignFieldModel;
		case FieldType.image:
			const imageSignFieldModel = new ImageSignFieldModel(fieldModel);
			imageSignFieldModel.setSignService(signService);
			return imageSignFieldModel;
		case FieldType.checkBox:
			const checkBoxSignFieldModel = new CheckBoxSignFieldModel(fieldModel);
			checkBoxSignFieldModel.setSignService(signService);
			return checkBoxSignFieldModel;
		default:
			console.error(`Field type ${fieldModel.type} is not supported`);
			return null;
	}
}
