// filename: attachment.sign-field.model.ts
import {IAddAttachmentFile, IFieldExt, isValidation, IValidation} from '@core/api/data-access';
import {DcIconStruct, FaIconSize} from '@shared-types';
import {SignFieldModel} from '../../sign-field.model';
import {computed, signal, Signal, WritableSignal} from '@angular/core';
import {SignAttachmentFileModel} from './sign-attachment-file.model';
import {IStatusIcon, SignAttachmentStatus} from './sign-attachment.types';
import {ISignalFormControlValidation} from '@shared/signals-extension';
import {ValidationModel, ValidationModelFactory} from '@validations/data-access';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class AttachmentSignFieldModel extends SignFieldModel {
	_initCustomTypeStyles(): void {}
	/* ----------------------------------------------- Constants ----------------------------------------------- */
	readonly fieldTypeIcon: DcIconStruct = {name: 'paperclip', type: 'duotone', size: FaIconSize.xl};
	/* ---------------------------------------------- Data Signals ---------------------------------------------- */
	files: WritableSignal<SignAttachmentFileModel[]> = signal([]);

	override validationRuleData: WritableSignal<ValidationModel> = signal<ValidationModel>(null);

	initValidators(): ISignalFormControlValidation[] {
		if (this.validationRuleData && this.validationRuleData()) {
			this.isRequired.set(true);
			this.required = true;
			const validatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
				const validationRule = this.validationRuleData();
				if (validationRule) {
					return validationRule.isValid() ? null : {invalid: true};
				}
				return {invalid: true};
			};
			const signalFCValidation: ISignalFormControlValidation = {
				name: 'attachment validation',
				validator: validatorFn,
				description: 'Attachment validation',
				errorMessage: 'Attachment validation error',
				successMessage: 'Attachment validation success',
			};
			return [signalFCValidation];
		}
		return [];
	}

	disabled: WritableSignal<boolean> = signal(false);
	isRequired: WritableSignal<boolean> = signal(false);
	minFiles: WritableSignal<number> = signal(0);
	maxFiles: WritableSignal<number> = signal(0);

	initSignalFC(): void {
		// check whther has validation rule for this field:
		const validationRuleString = this.settings$().ValidationRule;
		if (validationRuleString) {
			const validatinSchema: IValidation = JSON.tryParse<any>(validationRuleString)?.validations?.at(0);
			if (validatinSchema && isValidation(validatinSchema)) {
				const validationRule = ValidationModelFactory(validatinSchema);
				if (!this.validationRuleData) {
					this.validationRuleData = signal<ValidationModel>(null);
				}
				this.validationRuleData.set(validationRule);
			}
		}
	}

	isValid: Signal<boolean> = computed(() => {
		const isRequired = this.isRequired();
		const files = this.files();
		if (!isRequired) return true;
		return files.length >= this.minFiles();
	});

	isLocked: Signal<boolean> = computed(() => {
		return this.validationRuleData() && this.isValid() && this.validationRuleData().isValid();
	});

	constructor(init: IFieldExt) {
		super(init);
		this.isRequired.set(init.required);

		const fieldSettingsData = init.settings$();
		// attachment counts:
		this.minFiles.set(fieldSettingsData.AttachmentFileCountMin);
		this.maxFiles.set(fieldSettingsData.AttachmentFileCount);

		this.initSignalFC();
		this.initBaseValidators();
	}

	/* ------------------------------------------------  Signals ------------------------------------------------ */
	minFilesText: Signal<string> = computed(() => {
		const minFilesValue = this.minFiles();
		return minFilesValue === 1 ? window.translate('pages.sign-doc.attachments.min_to_upload_one') : window.translate('pages.sign-doc.attachments.min_to_upload', {value: minFilesValue});
	});

	status: Signal<SignAttachmentStatus> = computed(() => {
		const minFilesValue = this.minFiles();
		const filesValue = this.files();

		if (this.isRequired()) {
			if (filesValue.length < minFilesValue) {
				return SignAttachmentStatus.Error;
			}
		} else {
			if (filesValue.length < minFilesValue) {
				return SignAttachmentStatus.Default;
			}
		}
		if (filesValue.length >= minFilesValue) {
			return SignAttachmentStatus.Success;
		}
	});

	icon: Signal<IStatusIcon> = computed(() => {
		const status = this.status();
		switch (status) {
			case SignAttachmentStatus.Default:
				return {name: 'circle-info', color: 'bg-gray-300'};
			case SignAttachmentStatus.Success:
				return {name: 'circle-check', color: 'var(--dc-success)'};
			case SignAttachmentStatus.Error:
				const minFilesValue = this.minFiles();
				const text = window.translate('pages.sign-doc.attachments.required-attachment-tooltip', {value: minFilesValue});
				return {name: 'circle-info', color: 'var(--dc-error)', text: text};
		}
	});

	/* ----------------------------------------------- Methods ----------------------------------------------- */
	public addFile(file: SignAttachmentFileModel): void {
		this.files.update(files => [...files, file]);
	}

	public asAttachmentRequestFile(): IAddAttachmentFile[] {
		if (!this.files()) return [];
		const attachments = this.files();
		return attachments.map(attachment => {
			return {
				fileData: attachment.file()?.fileEncode,
				fileType: attachment.file()?.type,
			};
		});
	}

	getValue(): string | null {
		return this.signalFC.getValue();
	}
}
