import {SignService} from '../sign.service';
import {computed, Signal} from '@angular/core';
import {SignButtonStateType, SignStateStruct, SignStateType, SignSubmissionButtonStateStruct} from '../../types';
import {DcColorType} from '@shared-types';

export class SignStateService {
	constructor(private signService: SignService) {}

	/**
	 * @description Returns the current sign state.
	 *    type: SignButtonStateType;
	 *    disabled: boolean;
	 *    icon: string;
	 *    label?: string;
	 *    color: DcColorType;
	 *    value?: any;
	 */
	public readonly signState: Signal<SignStateStruct> = computed(() => {
		// SignStateType.FieldsLeft:
		const requiredInternalFieldsLeft: number = this.signService.Fields.requiredInternalFieldsLeft()?.length;
		if (requiredInternalFieldsLeft > 0) {
			return {
				type: SignStateType.FieldsLeft,
				disabled: false,
				color: DcColorType.Warn,
				value: requiredInternalFieldsLeft,
			};
		}

		// SignSubmissionButtonStateType.FieldsError:
		const invalidFields: number = this.signService.Fields.invalidInternalFields()?.length;
		if (invalidFields > 0) {
			console.log('invalidFields', this.signService.Fields.invalidInternalFields());
			return {
				type: SignStateType.FieldsError,
				disabled: true,
				color: DcColorType.Error,
				value: invalidFields,
			};
		}

		// SignSubmissionButtonStateType.CanGoToAttachments:
		const canGoToAttachments: boolean = this.signService.Fields.visibleAttachmentFields()?.length > 0;
		if (canGoToAttachments) {
			return {
				type: SignStateType.CanGoToAttachments,
				color: DcColorType.Info,
				disabled: false,
			};
		}

		// SignSubmissionButtonStateType.CanSubmit:
		return {
			type: SignStateType.CanSubmit,
			color: DcColorType.Success,
			disabled: false,
		};
	});

	/**
	 * @description Returns the state of the dynamic button, according to the sign state.
	 */
	public readonly dynamicButtonState: Signal<SignSubmissionButtonStateStruct> = computed(() => {
		const signState: SignStateStruct = this.signService.SignState.signState();
		const canSignInClick: boolean = this.signService.signIndicators()?.canSignInClick;
		switch (signState.type) {
			case SignStateType.FieldsLeft:
				if (canSignInClick) {
					return {
						type: SignButtonStateType.CanSignInClick,
						disabled: false,
						label: 'struct.pdf-viewer.signinclickbtn',
						icon: 'fa-duotone fa-pen px-1',
						color: DcColorType.Success,
					};
				}
				return {
					type: SignButtonStateType.CanSubmit,
					disabled: true,
					label: 'pages.sign-doc.attachments.approve-and-submit',
					icon: 'fa-duotone fa-sign-in px-1',
					color: DcColorType.Success,
				};
			case SignStateType.FieldsError:
				return {
					type: SignButtonStateType.CanSubmit,
					disabled: true,
					label: 'pages.sign-doc.attachments.approve-and-submit',
					icon: 'fa-duotone fa-sign-in px-1',
					color: DcColorType.Success,
				};
			case SignStateType.CanGoToAttachments:
				return {
					type: SignButtonStateType.CanGoToAddAttachments,
					disabled: false,
					label: 'dccomp.dc-sign.toolbar.continue-add-attachments',
					icon: 'fa-duotone fa-paperclip px-1',
					color: DcColorType.Success,
				};
			case SignStateType.Submit:
				return {
					type: SignButtonStateType.CanSubmit,
					disabled: false,
					label: 'pages.sign-doc.attachments.approve-and-submit',
					icon: 'fa-duotone fa-sign-in px-1',
					color: DcColorType.Success,
				};
			case SignStateType.CanSubmit:
				return {
					type: SignButtonStateType.CanSubmit,
					disabled: false,
					label: 'pages.sign-doc.attachments.approve-and-submit',
					icon: 'fa-duotone fa-sign-in px-1',
					color: DcColorType.Success,
				};
			default:
				return undefined;
		}
	});
}
