export type IStatusIcon = {
	name: string;
	color: string;
	text?: string;
};

export enum SignAttachmentStatus {
	Default,
	Success,
	Error,
}
