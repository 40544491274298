// filename: meta-data.sign-field.model.ts
import {IField} from '@core/api/data-access';
import {DcIconStruct, FaIconSize} from '@shared-types';
import {SignFieldModel} from '../sign-field.model';
import {signal, Signal, WritableSignal} from '@angular/core';
import {ISignFieldValidationRule} from '@global-data';
import {ISignalFormControlValidation} from '@shared/signals-extension';

export class MetaDataSignFieldModel extends SignFieldModel {
	readonly fieldTypeIcon: DcIconStruct = {name: 'info-circle', type: 'duotone', size: FaIconSize.xl};

	initSignalFC(): void {}
	_initCustomTypeStyles(): void {}

	override validationRuleData: WritableSignal<ISignFieldValidationRule>;

	initValidators(): ISignalFormControlValidation[] {
		return [];
	}

	isValid: Signal<boolean> = signal(true);

	constructor(init: IField) {
		super(init);
	}

	getValue(): string | null {
		return this.signalFC.getValue();
	}
}
