import { computed, Signal, signal, WritableSignal } from '@angular/core';
import { Validators } from '@angular/forms';
import { ExternalPdfFieldType, IField } from '@core/api/data-access';
import { FieldModel } from '@cycles/data-access';
import { ISignField, ISignFieldValidationRule, ISignService } from '@global-data';
import { DcIconStruct, uuidv4 } from '@shared-types';
import { ISignalFormControlValidation, SignalFormControl, SignalFormControlValidation } from '@shared/signals-extension';
import { BE } from '@utils';
import { SignDocumentPageModel } from '../document';
import { SignFieldStylesModel } from './sign-field-styles.model';
import BESchema = BE.BESchema;

@BESchema()
export abstract class SignFieldModel extends FieldModel implements ISignField {
	readonly uid: string = uuidv4();

	protected constructor(init: IField) {
		super(init);
		this.styles = new SignFieldStylesModel(this);
		this.fieldTypeLabel = FieldModel.getFieldTypeLabel(this.type);
		this.signalFC._fc.setValue(this.value);
		this.initSignalFC();
		if (!this.title) {
			this.title = `${this.fieldTypeLabel} ${this.index + 1}`;
		}

		if(this.settings$().AttachmentFileCountMin === 0){
			this.settings$.update(settings => {
				settings.AttachmentFileCountMin = 1;
				return settings;
			});
		}
	}

	abstract _initCustomTypeStyles(): void;

	/* -------------------------------------------------------------------------------------------------------------- */
	public readonly fieldTooltip: Signal<string> = computed(() => {
		const label = this.fieldTypeLabel + (this.required ? ' *' : '');
		const validationRule = this.validationRuleData()?.['validationRule'];
		if (validationRule) return `${label} (${validationRule.description ?? validationRule.name})`;
		return label;
	});

	/* ---------------------------------------------- Field's Type ---------------------------------------------- */
	readonly fieldTypeLabel: string;
	abstract readonly fieldTypeIcon: DcIconStruct;

	/* ----------------------------------------------  Field Value ---------------------------------------------- */
	abstract initSignalFC(): void;

	abstract getValue(): string | null;

	public readonly signalFC: SignalFormControl = new SignalFormControl(undefined);

	/* ----------------------------------------------  Validations ---------------------------------------------- */
	abstract isValid: Signal<boolean>;

	validationRuleData: WritableSignal<ISignFieldValidationRule> = signal(null);

	abstract initValidators(): ISignalFormControlValidation[];

	initBaseValidators(): void {
		const validations: ISignalFormControlValidation[] = [];
		if (this.required) {
			const requiredValidation: ISignalFormControlValidation = {
				name: 'required',
				validator: Validators.required,
				description: '',
				errorMessage: window.translate('pages.cw.fieldinfo.validID.input-error'),
				successMessage: '',
			};
			validations.push(requiredValidation);
		}

		const initValidators = this.initValidators() || [];
		validations.push(...initValidators);

		this.signalFC.setValidation(...validations.map(v => new SignalFormControlValidation(v)));
		this.signalFC.update();
	}

	/* -------------------------------------------- Active Indicator -------------------------------------------- */
	public isActive: Signal<boolean> = signal(false);

	public setSignService(signService: ISignService): void {
		this.isActive = computed(() => signService?.Fields.activeFieldIndex() === this.index);
	}

	/* ------------------------------------------------  Field's Page ------------------------------------------------ */
	pageRef: WritableSignal<SignDocumentPageModel> = signal(null);

	/* ---------------------------------------------- Field Styles ---------------------------------------------- */
	/**
	 * Represents the styles of the sign field.
	 * This is an instance of the SignFieldStylesModel class.
	 * @type {SignFieldStylesModel}
	 */
	styles: SignFieldStylesModel;
	customTypeStyles: CSSStyleDeclaration = {} as CSSStyleDeclaration;

	/* --------------------------------------------- Static Methods --------------------------------------------- */
	public static isExternalField(type: any): boolean {
		return Object.values(ExternalPdfFieldType).includes(type);
	}
}
