import {computed, signal, Signal, WritableSignal} from '@angular/core';
import {SignDocumentModel, SignDocumentPageModel} from '../../models';
import {SignService} from '../sign.service';
import {ISignDocumentService} from '@global-data';

export class SignDocumentService implements ISignDocumentService {
	activePage: WritableSignal<number> = signal(1);

	constructor(protected signService: SignService) {}

	/* ------------------------------------------------  Signals ------------------------------------------------ */
	public readonly document: Signal<SignDocumentModel> = computed(() => {
		return this.signService.signCycleModel().documentModel();
	});

	public readonly pages: Signal<Map<number, SignDocumentPageModel>> = computed(() => {
		return this.document().pages;
	});
}
