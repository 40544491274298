// filename: check-box.sign-field.model.ts
import {CheckboxValueType, getCheckboxValue, IField} from '@core/api/data-access';
import {DcIconStruct, FaIconSize} from '@shared-types';
import {SignFieldModel} from '../sign-field.model';
import {computed, Signal} from '@angular/core';
import {ISignalFormControlValidation} from '@shared/signals-extension';
import {ISignService} from '@global-data';

export class CheckBoxSignFieldModel extends SignFieldModel {
	readonly fieldTypeIcon: DcIconStruct = {name: 'check-square', type: 'duotone', size: FaIconSize.xl};

	initValidators(): ISignalFormControlValidation[] {
		return [];
	}

	public checked: Signal<boolean> = computed(() => {
		return this.signalFC.value() === CheckboxValueType.Yes;
	});

	constructor(init: IField) {
		super(init);
	}
	_initCustomTypeStyles(): void {}

	override setSignService(signService: ISignService) {
		super.setSignService(signService);
		if (this.settings$().Metatag) {
			const cycleMetaTags = signService.signCycleModel()?.metaTags;
			if (cycleMetaTags) {
				const metaTag = cycleMetaTags.find(tag => this.settings$().Metatag === tag.metaTag);
				if (metaTag) {
					const checkboxValue = getCheckboxValue(metaTag.value);
					this.signalFC.setValue(checkboxValue);
				}
			}
		}
	}

	initSignalFC(): void {
		if (this.value === undefined) {
			this.uncheck();
		} else {
			this.signalFC.setValue(this.value);
		}
	}

	isValid: Signal<boolean> = computed(() => {
		const isValid = this.signalFC.isValid();
		const isFilled = this.checked();
		const isRequired = this.required;
		return isRequired ? isValid && isFilled : isValid;
	});

	check(): void {
		this.signalFC.setValue(CheckboxValueType.Yes);
	}

	uncheck(): void {
		this.signalFC.setValue('Off');
	}

	toggle(): void {
		this.checked() ? this.uncheck() : this.check();
	}

	getValue(): string | null {
		return this.checked() ? CheckboxValueType.Yes : CheckboxValueType.Off;
	}
}
