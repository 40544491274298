import {computed, Signal, signal, WritableSignal} from '@angular/core';
import {CycleStatusType, FieldType, ICompanySettings, ICycle, IField, IStamp, IUser, Policy, StampSignatureType, StationStatusType} from '@core/api/data-access';
import {CycleLogsModel, CycleModel} from '@cycles/data-access';
import {FieldModelExtensionsUtils} from '@cycles/utils';
import {ISignCycle, ISignService, ISignStation, SignIndicatorsStruct, StoredFile} from '@global-data';
import {ValidationRuleModel} from '@validations/validation-rules/data-access';
import {SignService} from '../../services';
import {SignDocumentModel} from '../document';
import {SignFieldModel, TextBoxSignFieldModel} from '../fields';
import {SignFieldModelFactory} from '../fields/sign-field.model-factory';
import {SignStationModel} from '../station';
import isDigitalSignatureField = FieldModelExtensionsUtils.isDigitalSignatureField;

export class SignCycleModel extends CycleModel implements ISignCycle {
	signServiceRef: ISignService;
	logs: CycleLogsModel = null;

	constructor(
		init: Partial<SignCycleModel | ICycle>,
		public signerId: string,
		signService: SignService,
	) {
		super(init);
		this.signServiceRef = signService;
		this.stations$.set(this.stations.map(station => new SignStationModel(station)));
		this.logs = new CycleLogsModel(this.log);
	}

	/* ----------------------------------------------- Indicators ----------------------------------------------- */
	public initSignIndicators(activeUser: IUser, isPermanentSigner: boolean, companySettings: ICompanySettings, internalFields: SignFieldModel[], stamps: IStamp[]): void {
		const indicatorsToUpload: SignIndicatorsStruct = this.signCycleIndicators();

		/* -------------- Permanent Signer: -------------- */
		indicatorsToUpload.isPermanentSigner = isPermanentSigner;

		/* --------------  Comment Policy: -------------- */
		switch (companySettings.commentPolicy) {
			case Policy.NoOne:
				indicatorsToUpload.canCommentCycle = false;
				break;
			case Policy.Everyone:
				indicatorsToUpload.canCommentCycle = true;
				indicatorsToUpload.autoOpenCommentDialog = companySettings.autoRemarks;
				break;
			case Policy.UsersOnly:
				indicatorsToUpload.canCommentCycle = true;
				indicatorsToUpload.autoOpenCommentDialog = companySettings.autoRemarks;
				break;
		}

		/* --------------- Reject Policy: --------------- */
		switch (companySettings.rejectPolicy) {
			case Policy.NoOne:
				indicatorsToUpload.canRejectCycle = false;
				break;
			case Policy.Everyone:
				indicatorsToUpload.canRejectCycle = true;
				break;
			case Policy.UsersOnly:
				indicatorsToUpload.canRejectCycle = isPermanentSigner;
				break;
		}

		/* ---------------  Alert Policy: --------------- */
		indicatorsToUpload.canAlertCycle = companySettings.defaultAllowAlert;

		/* ---------------  Splash Screen: --------------- */
		indicatorsToUpload.openSplashScreen = companySettings.useSplashScreen;
		indicatorsToUpload.hasOpeningMessage = Boolean(this.logs.openingMessage);

		/* ---------------  Form Filler: --------------- */
		const allFillerTypesHidden: boolean = companySettings.fieldFillerHiddenTypes?.length === 6;
		indicatorsToUpload.canShowFormFillerBtn = !allFillerTypesHidden && internalFields.length > 0;
		indicatorsToUpload.autoOpenFormFiller = companySettings.fieldFillerAuto && indicatorsToUpload.canShowFormFillerBtn && window.isMobile();

		/* ---------------  Sign In Click: --------------- */
		if (companySettings.autoApproveCycle && isPermanentSigner) {
			const hasDefaultStamp: boolean = stamps?.length && activeUser.preferences.defaultStamp >= 2;
			if (hasDefaultStamp) {
				const signatureFieldsWithoutStamps = internalFields.filter(
					(field: SignFieldModel) => field.type === FieldType.signature && field.settings$().AllowStampSignature === StampSignatureType.NotAllowed,
				);
				const hasNotSignatureRequiredFields: boolean = internalFields.some((field: SignFieldModel) => field.type !== FieldType.signature && field.required);
				indicatorsToUpload.canSignInClick = signatureFieldsWithoutStamps.length > 0 && !hasNotSignatureRequiredFields;
			}
		}

		/* --------------------------------- GTM ID --------------------------------- */
		indicatorsToUpload.googleTagManager = companySettings?.gtmId;

		/* -------------------------------------- Assign indicators to signal: -------------------------------------- */
		this.signCycleIndicators.set(indicatorsToUpload);
	}

	signCycleIndicators: WritableSignal<SignIndicatorsStruct> = signal({
		isPermanentSigner: false,
		autoOpenCommentDialog: false,
		canAlertCycle: false,
		canCommentCycle: false,
		canRejectCycle: false,
		canShowInspectorButtons: false,
		autoOpenFormFiller: false,
		canShowFormFillerBtn: false,
		canSignInClick: false,
		hasRequiredGpsFields: false,
		openSplashScreen: false,
		hasOpeningMessage: false,
		googleTagManager: undefined,
	});

	/* ------------------------------------------------  Station ------------------------------------------------ */
	public readonly stations$: WritableSignal<ISignStation[]> = signal([]);

	public readonly pastStations$: Signal<ISignStation[]> = computed(() => {
		const stations: ISignStation[] = this.stations$();
		const pastStationStatuses: StationStatusType[] = [StationStatusType.Approved, StationStatusType.Rejected, StationStatusType.Alert, StationStatusType.TimedOut];
		return stations.filter(station => pastStationStatuses.includes(station.status));
	});

	public readonly activeStation$: Signal<ISignStation> = computed(() =>
		this.stations$()?.find(station => station.status == StationStatusType.InProcess && station.users.some(user => user.user === this.signerId)),
	);

	public readonly futureStations$: Signal<ISignStation[]> = computed(() => {
		const stations: ISignStation[] = this.stations$();
		return stations.filter(station => station.status === StationStatusType.Waiting);
	});

	/* ------------------------------------------------- Signer ------------------------------------------------- */
	public canSign(userId: string): boolean {
		return this.status === CycleStatusType.Cycling && this.activeStation$()?.users.some(user => user.user === userId);
	}

	/* ------------------------------------------------ Document ------------------------------------------------ */
	private _documentModel: WritableSignal<SignDocumentModel> = signal(null);
	public documentModel: Signal<SignDocumentModel> = this._documentModel.asReadonly();

	setDocument(document: StoredFile): void {
		this._documentModel.set(new SignDocumentModel(document, this));
	}

	/* ------------------------------------------------- Fields ------------------------------------------------- */
	private _fields: WritableSignal<SignFieldModel[]> = signal(null);
	public fields: Signal<SignFieldModel[]> = this._fields.asReadonly();

	public addField(...fields: SignFieldModel[]): void {
		this._fields.set(this.fields().concat(fields));
	}

	public setFields(fields: IField[], override: boolean): {needValidationRules: boolean} {
		const fieldsToSet: SignFieldModel[] = fields.flatMap((field: IField) => {
			if (field instanceof SignFieldModel) return field;
			field = SignFieldModelFactory(field, this.signServiceRef, this.documentModel()?.pagesCount);
			if (!field || isDigitalSignatureField(field)) return [];
			return field as SignFieldModel;
		});
		this._fields.set(override ? fieldsToSet : this.fields().concat(fieldsToSet));
		return {
			needValidationRules: fieldsToSet.some(field => Boolean(field.validationRuleData())),
		};
	}

	public getField(index: number): SignFieldModel {
		return this.fields().find(field => field.index === index);
	}

	public setFieldsValidationRules(validationRules: Map<string, ValidationRuleModel>): void {
		this._fields.update((fields: SignFieldModel[]) => {
			fields.forEach((f: SignFieldModel) => {
				switch (f?.type) {
					case FieldType.textBox:
						const field = f as TextBoxSignFieldModel;
						if (field.validationRuleData()?.validationRule === null) {
							const validationRuleKey = field.validationRuleData()?.key;
							if (validationRuleKey) {
								if (validationRules?.has(validationRuleKey)) {
									const validationRule = validationRules.get(validationRuleKey);

									if (!validationRule?.validator) {
										// TODO: log to sentry
										return;
									}
									field.validationRuleData().setValidationRule(validationRule);
									field.initBaseValidators();
								}
							}
						}
				}
			});
			return fields;
		});
	}
}
