import {computed, signal, Signal, WritableSignal} from '@angular/core';
import {FieldRect} from '@core/api/data-access';
import {ISignFieldStyles} from '@global-data';
import {SignFieldModel} from './sign-field.model';

export class SignFieldStylesModel implements ISignFieldStyles {
	public fieldClasses: WritableSignal<Set<string>> = signal(new Set<string>(['dc-sign-field']));
	public rect: Signal<FieldRect<number>> = computed(() => {
		const pageRef = this.fieldModel.pageRef();
		if (!pageRef) return null;
		const pageRenderedEvent = pageRef.pageRenderedEvent;
		if (!pageRenderedEvent) return null;
		const pageViewport = pageRenderedEvent.source.pdfPage.getViewport({scale: 1});
		if (pageViewport) {
			// page dimensions in px:
			const pageWidth = pageViewport.width;
			const pageHeight = pageViewport.height;

			// left in %
			const leftPercent = this.fieldModel.left * (100 / pageWidth);
			// top in %
			const topPercent = (pageHeight - this.fieldModel.top) * (100 / pageHeight);
			// width in %
			const widthPercent = this.fieldModel.width * (100 / pageWidth);
			// height in %
			const heightPercent = this.fieldModel.height * (100 / pageHeight);

			return {
				left: leftPercent,
				top: topPercent,
				width: widthPercent,
				height: heightPercent,
			};
		}
		return null;
	});

	public fieldStyles: Signal<CSSStyleDeclaration> = computed(() => {
		const styles: CSSStyleDeclaration = {} as CSSStyleDeclaration;

		// FIELD POSITION AND DIMENSIONS:
		const fieldRect = this.rect();
		if (fieldRect) {
			styles.top = `${fieldRect.top}%`;
			styles.left = `${fieldRect.left}%`;
			styles.width = `${fieldRect.width}%`;
			styles.height = `${fieldRect.height}%`;
		}

		// FONT	SIZE:
		// if the fontSize is 0 -> it's 'auto' font	size (fill the whole field with text)
		if (this.fieldModel.settings$().FontSize === 0) styles.fontSize = 'calc(100% * var(--scale-factor))';
		else styles.fontSize = `calc(${this.fieldModel.settings$().FontSize}px * var(--scale-factor))`;

		// spacial field styles:
		if (this.fieldModel.customTypeStyles) Object.assign(styles, this.fieldModel.customTypeStyles);

		return styles;
	});

	constructor(private fieldModel: SignFieldModel) {
		this.fieldClasses().add(fieldModel.type.toLowerCase());
	}
}
