import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {FeatureStoreNames} from '@shared-types';
import {CycleSignStore} from './cycle-sign.models';
import ICycleSignState = CycleSignStore.ICycleSignState;

export const cycleSignActions = createActionGroup({
	source: FeatureStoreNames.CYCLE_SIGN_FEATURE_KEY,
	events: {
		setSignCycle: props<ICycleSignState>(),
		loadSignCycle: emptyProps(),
		unloadSignCycle: emptyProps(),
	},
});
