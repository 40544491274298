// filename: signature.sign-field.model.ts
import {IField} from '@core/api/data-access';
import {DcIconStruct, FaIconSize} from '@shared-types';
import {SignFieldModel} from '../sign-field.model';
import {computed, Signal} from '@angular/core';
import {StoredFile} from '@global-data';
import {ISignalFormControlValidation} from '@shared/signals-extension';

export class SignatureSignFieldModel extends SignFieldModel {
	readonly fieldTypeIcon: DcIconStruct = {name: 'signature', type: 'duotone', size: FaIconSize.xl};

	constructor(init: IField) {
		super(init);
	}

	initSignalFC(): void {}
	_initCustomTypeStyles(): void {}

	initValidators(): ISignalFormControlValidation[] {
		return [];
	}

	isValid: Signal<boolean> = computed(() => {
		const isValid = this.signalFC.isValid();
		const isFilled = this.signalFC.value();
		const isRequired = this.required;
		return Boolean(isRequired ? isValid && isFilled : isValid);
	});

	getValue(): string | null {
		const value = this.signalFC.value();
		if (value) {
			return new StoredFile(value)?.fileEncode || null;
		}
		return null;
	}
}
