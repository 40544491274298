import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {EMPTY, switchMap, tap, withLatestFrom} from 'rxjs';
import {cycleSignActions} from './cycle-sign.actions';
import cycleSignSelectors from './cycle-sign.selectors';
import {LoaderService} from '@utils';

@Injectable()
export class CycleSignEffects {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private loaderService: LoaderService = inject(LoaderService);

	// if the cycleId is not equal to the current cycleId, then dispatch validationRulesActions.clearValidationsState
	setSignCycle$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(cycleSignActions.setSignCycle),
				withLatestFrom(this.store.select(cycleSignSelectors.selectCycleSignState)),
				switchMap(([, state]) => {
					debugger;
					return EMPTY;
				}),
			);
		},
		{dispatch: false},
	);

	loadSignCycle$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(cycleSignActions.loadSignCycle),
				tap(() => {
					this.loaderService.showLoader({text: 'Loading cycle...'});
				}),
			);
		},
		{dispatch: false},
	);
}
