import {InjectionToken} from '@angular/core';

export type IGTMConfig = Partial<{
	autoPushPageViewEvent: boolean;
}>;

export const defaultGTMConfig: IGTMConfig = {
	autoPushPageViewEvent: false,
};

export const GTM_CONFIG_TOKEN = new InjectionToken<IGTMConfig>('GTM_CONFIG', {
	providedIn: 'root',
	factory: () => defaultGTMConfig,
});
