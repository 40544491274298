import {BE} from '@utils';
import {IAddAttachmentFile, IAddAttachmentRequest} from '@core/api/data-access';
import BESchema = BE.BESchema;
import BEProp = BE.BEProp;

@BESchema()
export class AddAttachmentRequestModel implements IAddAttachmentRequest {
	@BEProp() cycleId: string;
	@BEProp() files: IAddAttachmentFile[];

	constructor(cycleId: string, files: IAddAttachmentFile[]) {
		this.cycleId = cycleId;
		this.files = files;
	}
}
