import {computed, effect, inject, Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {ISignField, ISignService, SignIndicatorsStruct} from '@global-data';
import {ImageSignFieldModel, SignCycleModel, SignFieldModel} from '../models';
import {SignDocumentService} from './sub-services/sign.document.service';
import {SignFieldsService} from './sub-services/sign.fields.service';
import {SignStateService} from './sub-services/sign.state.service';
import {AuthService} from '@auth/data-access';
import {CompanySettingsService} from '@company-settings/data-access';
import {
	ClientEventTriggerEntity,
	ClientEventValueType,
	CycleListType,
	FieldType,
	IAddAttachmentFile,
	IAddAttachmentResponse,
	ISubmitCycleFieldsStruct,
	ISubmitCycleResponse,
	SubmitCycleActionType,
} from '@core/api/data-access';
import {SignFieldsInspectorService} from './sub-services/sign.fields-inspector.service';
import {ISignSubmissionStruct} from '../types';
import {LoaderService, ToastService} from '@utils';
import {DialogControlService} from '@shared/dialog';
import {CyclesService, SequentialSigningService} from '@cycles/data-access';
import {catchError, concatMap, filter, finalize, Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {generateUrlFromTemplate, SignLandingPageType} from '@cycles/sign/landing-pages/data-access';
import {SubmitCycleRequestModel} from '../models/cycle/submit-cycle.model';
import {AddAttachmentRequestModel} from '../models/fields/field-types/attachment/add-attachment.schema';
import {ValidatorService} from '@validations/data-access';
import {DcError, ErrorType} from '@shared-types';
import {ValidationRulesService} from '@validations/validation-rules/data-access';
import {SignTagsService} from './sub-services/sign.tags.service';
import {ClientEventsManagementService} from '@cron/client-events-management';

@Injectable({
	providedIn: 'root',
})
export class SignService implements ISignService {
	/* ------------------------------------------ PROVIDERS / SERVICES ------------------------------------------ */
	public readonly authService: AuthService = inject(AuthService);
	public readonly companySettingsService: CompanySettingsService = inject(CompanySettingsService);
	public readonly cyclesService: CyclesService = inject(CyclesService);
	private readonly toastService: ToastService = inject(ToastService);
	private readonly router: Router = inject(Router);
	private readonly validatorService: ValidatorService = inject(ValidatorService);
	public readonly loaderService: LoaderService = inject(LoaderService);
	public readonly sequentialSigningService: SequentialSigningService = inject(SequentialSigningService);
	public readonly dialogService: DialogControlService = inject(DialogControlService);
	public readonly validationRulesService: ValidationRulesService = inject(ValidationRulesService);
	protected readonly clientEventsManagementService: ClientEventsManagementService = inject(ClientEventsManagementService);
	/* ---------------------------------------------  Sub Services: --------------------------------------------- */
	public readonly Fields: SignFieldsService = new SignFieldsService(this);
	public readonly Document: SignDocumentService = new SignDocumentService(this);
	public readonly SignState: SignStateService = new SignStateService(this);
	public readonly Tags: SignTagsService = new SignTagsService(this);

	/* -------- Fields Inspector Controller: -------- */
	public readonly FieldsInspectorController: SignFieldsInspectorService = new SignFieldsInspectorService(this);

	/* ------------------------------------------------  Signals ------------------------------------------------ */

	/* ----------------- Sign Cycle ----------------- */
	public readonly signCycleModel: WritableSignal<SignCycleModel> = signal(null);
	public readonly signIndicators: Signal<SignIndicatorsStruct> = computed(() => this.signCycleModel().signCycleIndicators());

	/* -------------------------------------------------- Data -------------------------------------------------- */
	public signLoader: WritableSignal<string> = signal(null);

	public readonly submissionState: WritableSignal<ISignSubmissionStruct> = signal({
		submissionType: SubmitCycleActionType.APPROVE,
		message: '',
	});

	/* ------------------------------------------------  Constructor ------------------------------------------------ */
	constructor() {
		/** Set the active page from the active field */
		effect(
			() => {
				const activeField: SignFieldModel = this.Fields.activeField();
				if (activeField) {
					const page: number = activeField.page;
					if (page) this.Document.activePage.set(page);
				}
			},
			{
				allowSignalWrites: true,
			},
		);

		// connect the sign loader to the global loader:
		this.loaderService.showSignal(this.signLoader);
	}

	/* ------------------------------------------------  Methods ------------------------------------------------ */
	/**
	 * @description Set the sign cycle model
	 * @param signCycleModel
	 */
	public setSignCycle(signCycleModel: SignCycleModel): void {
		this.signCycleModel.set(signCycleModel);
	}

	/**
	 * @description Check the field type and open the relevant dialog
	 * @param field
	 */
	public checkFieldDialog(field: SignFieldModel): void {
		switch (field?.type) {
			case FieldType.image:
				const imageField: ImageSignFieldModel = field as ImageSignFieldModel;
				const hasValidationRule: boolean = Boolean(imageField?.validationRuleData());
				if (hasValidationRule) {
					if (field.signalFC.isValid() && field.signalFC.value()) {
						this.toastService.open({
							message: 'dccomp.dc-sign.camera-dialog.image-are-validated',
							type: 'info',
						});
						return;
					} else {
						this.dialogService.open('validationField', {
							data: {field},
						});
					}
				} else {
					this.dialogService.open('imageField', {
						data: {field},
					});
				}
				break;
			case FieldType.signature:
				this.dialogService.open('signatureField', {
					data: {field},
				});
				break;
		}
	}

	/* -------------------------------------------------- HTTP -------------------------------------------------- */

	/**
	 * @description Submit the sign cycle
	 */
	public submitSignCycle(): Observable<ISubmitCycleResponse> {
		this.signLoader.set('dccomp.dc-sign.submit-loaders.send');
		if (isDevMode()) debugger;

		// submit request:
		const fieldsToSubmit: ISubmitCycleFieldsStruct = this._generateSubmitFields();
		const submitCycleRequest: SubmitCycleRequestModel = new SubmitCycleRequestModel(this.signCycleModel().id, this.submissionState().submissionType, fieldsToSubmit, this.submissionState().message);
		// attachments from a validation process, and uploaded attachments (if exists):
		const validatorAttachments: IAddAttachmentFile[] = this.validatorService.getValidatorAttachments() || [];
		const attachments: IAddAttachmentFile[] = [...this.Fields.Attachments.attachmentsToSubmit(), ...validatorAttachments];

		// Requests:
		let attachmentUpload$: Observable<boolean | IAddAttachmentResponse> = of(true);
		if (attachments.length) {
			// set "uploading attachments" loader:
			this.signLoader.set('dccomp.dc-sign.submit-loaders.upload-attachments');
			const addAttachmentsRequest: AddAttachmentRequestModel = new AddAttachmentRequestModel(this.signCycleModel().id, attachments);
			attachmentUpload$ = this.cyclesService.Document.addAttachment(addAttachmentsRequest).pipe(
				catchError(error => {
					this.signLoader.set(null);
					console.error('Error in addAttachment', error);
					throw DcError({
						name: 'Error in addAttachment',
						message: 'SignService -> submitSignCycle()',
						type: ErrorType.Backend,
						originalError: error,
						captureToSentry: true,
						source: this.constructor.name,
					});
				}),
			);
		}

		const submitCycle$ = this.cyclesService.Cycle.submit(submitCycleRequest).pipe(
			catchError(err => {
				this.toastService.open({
					message: 'dccomp.fast-signing.controls.errors.alert',
					type: 'error',
				});
				this.signLoader.set(null);
				console.error();
				return of(null);
			}),
		);

		return attachmentUpload$.pipe(
			filter(addAttachmentResponse => Boolean(addAttachmentResponse)),
			concatMap(() => {
				// set "submitting cycle" loader:
				this.signLoader.set('dccomp.dc-sign.submit-loaders.send');
				return submitCycle$;
			}),
			finalize(() => {
				this.signLoader.set(null);
				// if rejected:
				let toMatch: ClientEventValueType = undefined;
				switch (this.submissionState().submissionType) {
					case SubmitCycleActionType.APPROVE:
						toMatch = ClientEventTriggerEntity.ClientEventValueType.OnSignSubmit;
						break;
					case SubmitCycleActionType.REJECT:
						toMatch = ClientEventTriggerEntity.ClientEventValueType.OnSignReject;
						break;
					default:
						return;
				}
				if (toMatch) {
					const matchSign = this.clientEventsManagementService.checkClientEventTriggers(toMatch, this.signCycleModel().triggers);
					if (matchSign.length) {
						matchSign.forEach(trigger => this.clientEventsManagementService.executeClientEvent(trigger, this.signCycleModel()));
					}
				}
				// TODO: check if there is next cycle to sign (sequence)
				const nextRoute: string = this._getNextRoute();
				// clear state of sign cycle:
				this._clearSignCycleState();
				// redirect to next route:
				if (nextRoute)
					this.router.navigateByUrl(nextRoute).then(navigated => {
						if (navigated && isDevMode()) debugger;
					});
			}),
		);
	}

	/* -------------------------------------------------- Private -------------------------------------------------- */
	/**
	 * @description Generate the submition fields
	 * @private
	 */
	private _generateSubmitFields(): ISubmitCycleFieldsStruct {
		return this.Fields.fields().reduce((acc: ISubmitCycleFieldsStruct, field: SignFieldModel) => {
			if ([FieldType.metadata].some(type => type === field.type)) return acc;
			const value: string = field.getValue();
			if (value) acc[field.index] = value;
			return acc;
		}, {});
	}

	/**
	 * @description Get the next route
	 * @private
	 */
	private _getNextRoute(): string {
		if (this.authService.ActiveUser.isPermanent()) {
			if (this.sequentialSigningService.isInitialized() && this.sequentialSigningService.goToNextCycle(this.signCycleModel().id)) {
				return undefined;
			} else {
				const submissionType: SubmitCycleActionType = this.submissionState().submissionType;
				return submissionType === SubmitCycleActionType.REJECT ? `cycles/${CycleListType.Rejected}` : `cycles/${CycleListType.Waiting}`;
			}
		} else {
			const cycleId: string = this.signCycleModel().id;
			const userId: string = this.signCycleModel().user;
			switch (this.submissionState().submissionType) {
				case SubmitCycleActionType.APPROVE:
				case SubmitCycleActionType.ALERT:
					return generateUrlFromTemplate(SignLandingPageType.SignedThankYou, {
						id: cycleId,
						user: userId,
					});
				case SubmitCycleActionType.REJECT:
					const creator: string = this.authService.ActiveUser.activeUserDisplayName();
					return generateUrlFromTemplate(SignLandingPageType.SignedRejected, {
						id: cycleId,
						user: userId,
						creator,
					});
				default:
					throw new Error('Unknown submit action');
			}
		}
	}

	/**
	 * @description Clear the sign cycle state
	 * @private
	 */
	private _clearSignCycleState(): void {
		// remove the validation probe state:
		this.validatorService.reset();
	}
}
