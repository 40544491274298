// filename: image.sign-field.model.ts
import {IField, isValidation, IValidation} from '@core/api/data-access';
import {DcIconStruct, FaIconSize} from '@shared-types';
import {SignFieldModel} from '../sign-field.model';
import {computed, signal, Signal, WritableSignal} from '@angular/core';
import {StoredFile} from '@global-data';
import {ValidationModel, ValidationModelFactory} from '@validations/data-access';
import {ISignalFormControlValidation} from '@shared/signals-extension';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class ImageSignFieldModel extends SignFieldModel {
	readonly fieldTypeIcon: DcIconStruct = {name: 'image', type: 'duotone', size: FaIconSize.xl};
	isValid: Signal<boolean> = computed(() => {
		const isValid = this.signalFC.isValid();
		const isFilled = this.signalFC.value();
		const isRequired = this.required;
		return isRequired ? isValid && isFilled : isValid;
	});

	constructor(init: IField) {
		super(init);
		this.initSignalFC();
		this.initBaseValidators();
	}

	override validationRuleData: WritableSignal<ValidationModel> = signal<ValidationModel>(null);

	_initCustomTypeStyles(): void {}

	initSignalFC(): void {
		// check whther has validation rule for this field:
		const validationRuleString = this.settings$().ValidationRule;
		if (validationRuleString) {
			const validatinSchema: IValidation = JSON.tryParse<any>(validationRuleString)?.validations?.at(0);
			if (validatinSchema && isValidation(validatinSchema)) {
				const validationRule = ValidationModelFactory(validatinSchema);
				if (!this.validationRuleData) {
					this.validationRuleData = signal<ValidationModel>(null);
				}
				this.validationRuleData.set(validationRule);
			}
		}
	}

	initValidators(): ISignalFormControlValidation[] {
		if (this.validationRuleData && this.validationRuleData()) {
			const validatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
				const validationRule = this.validationRuleData();
				if (validationRule) {
					return validationRule.isValid() ? null : {invalid: true};
				}
				return {invalid: true};
			};
			const signalFCValidation: ISignalFormControlValidation = {
				name: 'image validation',
				validator: validatorFn,
				description: 'Image validation',
				errorMessage: 'Image validation error',
				successMessage: 'Image validation success',
			};
			return [signalFCValidation];
		}
		return [];
	}

	getValue(): string | null {
		const value = this.signalFC.value();
		const image:string = value?.image ? value.image : value;
		if (image) {
			return new StoredFile(image)?.fileEncode || null;
		}
		return null;
	}
}
