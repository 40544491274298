import {ComponentRef, computed, Signal, signal, WritableSignal} from '@angular/core';
import {ISignDocumentPage, ISignField, ISignPageComponent} from '@global-data';
import {PageRenderedEvent} from 'ngx-extended-pdf-viewer';
import {SignFieldModel} from '../../fields';
import {SignDocumentModel} from '../sign-document.model';

export class SignDocumentPageModel implements ISignDocumentPage {
	pageNumber: number;

	pageFieldIndexes: WritableSignal<Set<number>> = signal(new Set<number>());

	pageFieldsArray: Signal<SignFieldModel[]> = computed(() => {
		return this.signDocument.signCycle.fields()?.filter((field: SignFieldModel) => field.page === this.pageNumber && !SignFieldModel.isExternalField(field.type));
	});

	allRequiredFieldsAreFilled: Signal<boolean> = computed(() => {
		return this.pageFieldsArray().every((field: ISignField) => field.isValid());
	});

	getPageFields(filterFn: (field: ISignField) => boolean): SignFieldModel[] {
		return this.pageFieldsArray().filter(filterFn);
	}

	signPageComponentRef: ComponentRef<ISignPageComponent>;

	constructor(
		public signDocument: SignDocumentModel,
		public pageRenderedEvent: PageRenderedEvent,
		pageNumber?: number,
	) {
		this.pageNumber = pageNumber ?? pageRenderedEvent.pageNumber;
	}

	public addField(field: ISignField): void {
		this.pageFieldIndexes().add(field.index);
		field.pageRef.set(this);
	}
}
