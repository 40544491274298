import {IFieldExt, IRegexValidation, ITextFieldValidationRuleSchema, IValidationRuleExt, validateRegexValidation} from '@core/api/data-access';

export function textFieldValidationRuleFactory(field: IFieldExt): ITextFieldValidationRuleSchema | null {
	const validationRuleString: string | null = field.settings$()?.ValidationRule;
	if (validationRuleString) {
		const validationRule: IRegexValidation = validateRegexValidation(validationRuleString);
		const validationRuleModel = new TextFieldValidationRuleModel(validationRule);
		if (validationRule) {
			return validationRuleModel;
		}
	}

	return null;
}

export class TextFieldValidationRuleModel implements ITextFieldValidationRuleSchema {
	type: 'Regex';

	key: string;
	validationRule: IValidationRuleExt | null = null;

	constructor(validationRule: IRegexValidation) {
		this.key = validationRule?.key;
		this.validationRule = null;
	}

	setValidationRule(validationRule: IValidationRuleExt): void {
		this.validationRule = validationRule;
	}
}
