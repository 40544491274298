import {inject, Injectable} from '@angular/core';
import {AbstractTriggerEntity, ClientEventTriggerEntity, ClientEventValueType, ICycle, IUser, TriggerActionType, TriggerEntity, TriggerEventType} from '@core/api/data-access';
import {MetaTagsSignalStore} from '@meta-tags/data-access';
import {isUniquePlaceholderTemplateString, parseUniquePlaceholderTemplateString, UniquePlaceholderDataSource} from '@shared/ui';
import {GoogleTagManagerService} from './google-tag-manager.service';
import {AuthService} from '@auth/data-access';

@Injectable({
	providedIn: 'root',
	deps: [GoogleTagManagerService, MetaTagsSignalStore],
})
export class ClientEventsManagementService {
	/* ------------------------------------------ Services / Providers ------------------------------------------ */
	private readonly googleTagManagerService: GoogleTagManagerService = inject(GoogleTagManagerService);
	public readonly authService: AuthService = inject(AuthService);
	/* ------------------------------------------ Constructor ------------------------------------------ */
	constructor() {}

	public hasClientEventTriggers(triggers: AbstractTriggerEntity[]) {
		return triggers.some(t => t && t['event'] && t['event'] === TriggerEventType.ClientEvent);
	}

	public checkClientEventTriggers(clientEventToCheck: ClientEventValueType, triggers: TriggerEntity[]) {
		const matchingTriggers = triggers.filter(t => t && t['value'] === clientEventToCheck);
		return matchingTriggers.map(t => t as ClientEventTriggerEntity);
	}

	public executeClientEvent(trigger: ClientEventTriggerEntity, cycle: ICycle) {
		if (trigger.event === TriggerEventType.ClientEvent) {
			switch (trigger.action.type) {
				case TriggerActionType.Gtm:
					this.applyGtmAction(trigger, cycle);
					console.log('[ClientEventsManagementService] GTM action applied', trigger);
			}
		}
	}

	private applyGtmAction(trigger: ClientEventTriggerEntity, cycle: ICycle): void {
		const action = trigger.action;
		// remove 'on' from the value and split the string by capital letters
		const gtmEventName = Object.keys(ClientEventTriggerEntity.ClientEventValueType)
			[trigger.value]?.replace('On', '')
			.split(/(?=[A-Z])/)
			.join('_')
			.toLowerCase();
		const data = typeof action.data === 'string' ? JSON.parse(action.data) : action.data;
		const parsedData = this.placeholderDataObjectParser(data, cycle);
		this.googleTagManagerService.pushEvent(gtmEventName, parsedData);
	}

	private placeholderDataObjectParser(data: object, cycle: ICycle): Record<string, string> {
		const dataKeys = Object.keys(data);
		const activeUser = this.authService.ActiveUser.activeUser();
		const parsedData = {};
		for (const key of dataKeys) {
			const parsed = this.parseSinglePlaceholder(data[key], cycle, activeUser);
			if (parsed) parsedData[key] = parsed;
		}
		return parsedData;
	}

	private parseSinglePlaceholder(value: string, cycle: ICycle, activeUser: IUser): string {
		if (isUniquePlaceholderTemplateString(value)) {
			const parsedPh = parseUniquePlaceholderTemplateString(value);
			switch (parsedPh.source) {
				case UniquePlaceholderDataSource.meta_tag:
					const mtKey: string = parsedPh.key;
					const value = cycle.metaTags.find(mt => mt.metaTag === mtKey)?.value;
					if (value) return value;
					return '';
				case UniquePlaceholderDataSource.user_info:
					const userKey: string = Object.keys(activeUser).find(k => k.toLowerCase() === parsedPh.key.toLowerCase());
					if (userKey) return activeUser[userKey];
					return '';
				case UniquePlaceholderDataSource.constant:
					return parsedPh.label;
			}
		}
	}
}
