import {computed, Signal, signal, WritableSignal} from '@angular/core';
import {StoredFile} from '@global-data'

export class SignAttachmentFileModel {
	file: WritableSignal<StoredFile> = signal(null);
	size: WritableSignal<string> = signal('');

	constructor(init?: Partial<SignAttachmentFileModel>) {
		Object.assign(this, init);
	}

	/* -------------------------------------------------------------------------- */
	/*                                  Computed                                  */
	/* -------------------------------------------------------------------------- */

	isValid: Signal<boolean> = computed(() => {
		return Boolean(this.file());
	});

	fileName: Signal<string> = computed(() => {
		const fileName = this.file()?.name;
		if (fileName && fileName.length > 20) {
			return fileName.slice(0, 20) + '...';
		}
		return fileName;
	});
	icon: Signal<{name: string; color: string}> = computed(() => {
		const fileType = this.file()?.type;
		return fileType.includes('pdf')
			? {name: 'file-pdf', color: 'var(--dc-success)'}
			: {
					name: 'file-image',
					color: 'var(--dc-info)',
			  };
	});
}
