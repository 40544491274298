import {BE} from '@utils';
import {ISubmitCycleFieldsStruct, ISubmitCycleRequest, SubmitCycleActionType} from '@core/api/data-access';
import BEProp = BE.BEProp;
import BESchema = BE.BESchema;

@BESchema()
export class SubmitCycleRequestModel implements ISubmitCycleRequest {
	@BEProp() public cycleId: string;
	@BEProp() public action: SubmitCycleActionType;
	@BEProp() public fields: ISubmitCycleFieldsStruct<number, string> = {};
	@BEProp() public message: string = '';
	@BEProp() public rich: boolean = false;

	constructor(cycleId: string, action: SubmitCycleActionType, fields: ISubmitCycleFieldsStruct<number, string> = {}, message: string = '', rich: boolean = false) {
		this.cycleId = cycleId;
		this.action = action;
		this.fields = fields;
		this.message = message;
		this.rich = rich;
	}
}
