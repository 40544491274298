import {inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';
import {GTM} from '../data/gtm.types';
import {uuidv4} from '@shared-types';
import {GTM_CONFIG_TOKEN, IGTMConfig} from '../data/gtm.config';
import IGTMDataLayerObject = GTM.IGTMDataLayer;

declare global {
	interface Window {
		dataLayer: IGTMDataLayerObject[];
	}
}

@Injectable({
	providedIn: 'root',
})
export class GoogleTagManagerService {
	/* ------------------------------------------ Services / Providers ------------------------------------------ */
	private readonly GTM_CONFIG_TOKEN: IGTMConfig = inject(GTM_CONFIG_TOKEN);
	private readonly router: Router = inject(Router);
	private readonly rendererFactory: RendererFactory2 = inject(RendererFactory2);
	private readonly renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

	/* ------------------------------------------ Constructor ------------------------------------------ */
	constructor() {}

	/* --------------------------------------------- Public Methods --------------------------------------------- */
	public initializeGtm(gtmId: string): void {
		if (!this.#checkGtmLoaded()) {
			this.#addGtmToDom(gtmId);
			if (this.GTM_CONFIG_TOKEN.autoPushPageViewEvent) {
				this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.pushPageViewEvent());
			}
		}
	}

	public pushEvent(event: string, data: Record<string, any>): void {
		if (!this.#checkGtmLoaded()) return;
		window.dataLayer.push({
			event: `gtm.${event}`,
			data,
		});
	}

	public pushPageViewEvent(): void {
		if (!this.#checkGtmLoaded()) return;
		this.pushEvent('pageview', {
			page: {
				url: this.router.url,
				title: document.title,
			},
		});
	}

	/* --------------------------------------------  Private Methods -------------------------------------------- */
	/**
	 * Check if the GTM script is already loaded in the DOM by checking the dataLayer object
	 * @private
	 */
	#checkGtmLoaded(): boolean {
		return Boolean(window.dataLayer);
	}

	/**
	 * Validate the GTM ID (to	prevent XSS attacks)
	 * @private
	 */
	#validateGtmId(gtmId: string): boolean {
		const gtmIdPattern = /^(GTM-[0-9A-Z]{6,8}|G-[A-Z0-9]{10,})$/;
		return gtmIdPattern.test(gtmId);
	}

	/**
	 * Add the GTM script to the DOM
	 * @private
	 */
	#addGtmToDom(gtmId: string): void {
		//	Check if the GTM script is already loaded
		if (this.#checkGtmLoaded()) return;
		// Validate the GTM ID
		if (!this.#validateGtmId(gtmId)) throw new Error('Invalid GTM ID');

		// Generate a nonce
		const nonce = this.#generateNonce();

		// Create the GTM script
		const gtmScript = `
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${gtmId}');
		`;

		// Create the GTM noscript
		const noscript = `
			<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
			height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`;

		// Inject the header script element
		const scriptElement = this.renderer.createElement('script');
		scriptElement.type = 'text/javascript';
		scriptElement.text = gtmScript;
		scriptElement.setAttribute('nonce', nonce);
		this.renderer.appendChild(document.head, scriptElement);

		// Inject the noscript element
		const noscriptElement = this.renderer.createElement('noscript');
		noscriptElement.innerHTML = noscript;
		this.renderer.appendChild(document.body, noscriptElement);
	}

	#generateNonce(): string {
		return 'nonce-' + uuidv4();
	}
}
