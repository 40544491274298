import {computed, effect, signal, Signal, WritableSignal} from '@angular/core';
import {SignFieldModel} from '../../models';
import {SignService} from '../sign.service';
import {ISignFieldsService, StoredFile} from '@global-data';
import {FieldType, IStamp, StampSignatureType} from '@core/api/data-access';
import {SignFieldsAttachmentsService} from './sign.fields.attachments.service';
import {HiddenPlaceholder} from '@cycles/data-access';
import {ValidationModel} from '@validations/data-access';

export class SignFieldsService implements ISignFieldsService {
	/* ---------------------------------------------- Sub Services ---------------------------------------------- */
	public readonly Attachments: SignFieldsAttachmentsService = new SignFieldsAttachmentsService(this.signService);

	constructor(protected signService: SignService) {
		effect(() => {
			const activeField: SignFieldModel = this.activeField();
			if (activeField) this.signService.checkFieldDialog(activeField);
		});
	}

	/* ------------------------------------------------  Signals ------------------------------------------------ */
	/* ------------------- Fields ------------------- */
	public readonly fields: Signal<SignFieldModel[]> = computed(() => {
		return this.signService.signCycleModel().fields() || [];
	});

	/* ---------------- Active Field ---------------- */
	public readonly activeField: WritableSignal<SignFieldModel> = signal(null);
	public readonly activeFieldIndex: Signal<number> = computed(() => this.activeField()?.index);

	public resetActiveField(): void {
		this.activeField.set(null);
	}

	public readonly attachmentFields: Signal<SignFieldModel[]> = computed(() => this.fields().filter((field: SignFieldModel) => field.type === FieldType.attachment));
	public readonly visibleAttachmentFields: Signal<SignFieldModel[]> = computed(() => this.attachmentFields().filter((field: SignFieldModel) => !HiddenPlaceholder.isHiddenPlaceHolder(field.title)));
	public readonly requiredAttachmentFields: Signal<SignFieldModel[]> = computed(() => this.visibleAttachmentFields().filter((field: SignFieldModel) => field.required));

	/**
	 * @description Returns all fields (internal fields) except:
	 * - attachment
	 * - metadata
	 * - metaTag
	 */
	public readonly internalFields: Signal<SignFieldModel[]> = computed(() => {
		const fields: SignFieldModel[] = this.fields() || [];
		return fields?.filter((field: SignFieldModel) => !SignFieldModel.isExternalField(field.type)) || [];
	});

	/**
	 * @description Returns all internal fields that are not already signed (invalid).
	 */
	public readonly invalidInternalFields: Signal<SignFieldModel[]> = computed(() => {
		const internalFields: SignFieldModel[] = this.internalFields();
		return internalFields.filter((field: SignFieldModel) => !field.isValid());
	});

	/**
	 * @description Returns all internal fields that are required.
	 */
	public readonly requiredInternalFields: Signal<SignFieldModel[]> = computed(() => {
		const internalFields: SignFieldModel[] = this.internalFields();
		return internalFields.filter((field: SignFieldModel) => field.required);
	});

	/**
	 * @description Returns all internal fields that are required, and are not already signed (invalid).
	 */
	public readonly requiredInternalFieldsLeft: Signal<SignFieldModel[]> = computed(() => {
		const requiredInternalFields: SignFieldModel[] = this.requiredInternalFields();
		return requiredInternalFields.filter((field: SignFieldModel) => !field.isValid());
	});

	/* ----------------------------------------- Active Fied Indicators ----------------------------------------- */
	public readonly activeFieldDocumentVerification = computed(() => {
		const activeField = this.signService.Fields.activeField();
		if (activeField) {
			return (activeField.validationRuleData() as ValidationModel)?.asDocumentVerification();
		}
	});

	public readonly activeFieldDocumentValueToValidate = computed(() => {
		const signCycleMetaTags = this.signService.signCycleModel().metaTags;
		return this.activeFieldDocumentVerification()?.getValueToValidateStruct(signCycleMetaTags);
	});

	/* -------------------------------------------------------------------------------------------------------------- */
	/**
	 * @description Returns true if all fields are signed (valid) - internal and external (attachments).
	 */
	public readonly allFieldsSigned: Signal<boolean> = computed(() => {
		const internalFields: SignFieldModel[] = this.internalFields();
		const externalFields: SignFieldModel[] = this.Attachments.allRequiredAttachments();
		const allFields: SignFieldModel[] = [...internalFields, ...externalFields];
		return allFields.every((field: SignFieldModel) => field.isValid());
	});

	/* -------------------------------------------------------------------------------------------------------------- */
	public signInClick(): void {
		// get the user default signature
		this.signService.authService.ActiveUser.getActiveUserStamps().subscribe((stamps: IStamp[]) => {
			const stampsLength: number = stamps.length;
			const defaultStampIndex: number = this.signService.authService.ActiveUser.activeUser()?.preferences?.defaultStamp;
			//	The value of the default stamp index should start at 2, because the indexes 0 and 1 are the system's stamps.
			const realDefaultStampIndex: number = defaultStampIndex < 2 ? -1 : defaultStampIndex - 2;
			let defaultStamp: IStamp = stamps[realDefaultStampIndex];
			if (!defaultStamp && stampsLength > 2) {
				defaultStamp = stamps[stampsLength - 1];
			} else if (!defaultStamp && stampsLength) {
				defaultStamp = stamps[0];
			}

			const defaultStampImage: StoredFile = new StoredFile(defaultStamp?.image, defaultStamp?.imageType);
			// update all signature fields with the default signature
			const signatureFields: SignFieldModel[] = this.signService.Fields.fields().filter((field: SignFieldModel) => field.type === FieldType.signature && field?.settings$().AllowStampSignature === StampSignatureType.NotAllowed);
			if (signatureFields && defaultStampImage?.webEncode) signatureFields.forEach((field: SignFieldModel) => field.signalFC.setValue(defaultStampImage.webEncode));
			// if there are required attachments to sign, open the attachments' dialog,	else submit the cycle:
			if (!this.signService.Fields.requiredAttachmentFields().length) {
				this.signService.submitSignCycle().subscribe();
			}
		});
	}
}
